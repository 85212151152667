import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../Common/Footer";
import Reservationheader from "../../Common/Reservationheader";
import Select from "react-select";
import { UserContext } from "../../Contexts/UserContext";
import { useAuthHandling } from "../../Common/useAuthHandling";
import Flatpickr from "react-flatpickr";
import Config from "../../Config";
import moment from "moment";
import Nodatafound from "../../Common/Nodatafound";
import Toastmodal from "../../Common/Toastmodal";

function Revision() {
  const [tab, setTab] = useState(false);
  const [datefrom, setDateFrom] = useState(null);
  const [dateto, setDateTo] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [year,setYear] = useState([]);
  const [loader, setLoader] = useState(true);
  const [list, setList] = useState(null);
  const { updateHotel_select, hotel_select, hotel_selectid } =
    useContext(UserContext);
  const { isAuthorized, handleUnauthorized } = useAuthHandling();
  const [id, setId] = useState(hotel_selectid ? hotel_selectid : "");
  const [Error, setError] = useState({});
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [toastmodal, setToastmodal] = useState(false);
  const [changedData, setChangedData] = useState([]);
  const [correctall, setCorrectall] = useState([]);
  const [ip, setIp] = useState("");

  const handleRevisionStatusChange = (outerIndex, innerIndex, newStatus) => {
  
    const updatedReservations = list.map((data, i) => {
      if (i === outerIndex) {
        const updatedReservationList = data.reservation_list.map((reserve, index) => {
          if (index === innerIndex) {
            const change = {
              hotel_id: reserve.hotel_id,
              reservation_id: reserve.reservation_id,
              revision_status: newStatus,
              comments:reserve.comments,
              revised_amount:reserve.revised_amount
            };
            changedData.push(change);
  
            return {
              ...reserve,
              revision_status: newStatus
            };
          }
          return reserve;
        });
  
        return {
          ...data,
          reservation_list: updatedReservationList
        };
      }
      return data;
    });
  
  setList(updatedReservations);
 };

  // const handleCommentsChange = (listIndex, reserveIndex,name, value) => {
  //   const updatedList = [...list];
  //   updatedList[listIndex].reservation_list[reserveIndex][name] = value;
  //   setList(updatedList);
  // };

  const handleCommentsChange = (listIndex, reserveIndex, name, value) => {
    const updatedList = [...list];
    updatedList[listIndex].reservation_list[reserveIndex][name] = value;
  
    const updatedChangedData = changedData.map((change) => {
      if (
        change.hotel_id === updatedList[listIndex].reservation_list[reserveIndex].hotel_id &&
        change.reservation_id === updatedList[listIndex].reservation_list[reserveIndex].reservation_id
      ) {
        return {
          ...change,
          [name]: value
        };
      }
      return change;
    });
  
    if (!updatedChangedData.some(change =>
      change.hotel_id === updatedList[listIndex].reservation_list[reserveIndex].hotel_id &&
      change.reservation_id === updatedList[listIndex].reservation_list[reserveIndex].reservation_id
    )) {
      updatedChangedData.push({
        hotel_id: updatedList[listIndex].reservation_list[reserveIndex].hotel_id,
        reservation_id: updatedList[listIndex].reservation_list[reserveIndex].reservation_id,
        [name]: value,
        revision_status: updatedList[listIndex].reservation_list[reserveIndex].revision_status,
        comments: updatedList[listIndex].reservation_list[reserveIndex].comments,
        revised_amount: updatedList[listIndex].reservation_list[reserveIndex].revised_amount
      });
    }
  
    setChangedData(updatedChangedData); 
    setList(updatedList); 
  };
  

//   const handleRevisionStatusChange = (outerIndex, innerIndex, newStatus) => {
//     const updatedReservations = list.map((data, i) => {
//         if (i === outerIndex) {
//             const hotelId = data.reservation_list[innerIndex].hotel_id;
//             const reservationId = data.reservation_list[innerIndex].reservation_id;

//             if (!changedData[i]) {
//                 changedData[i] = {
//                     hotel_id: hotelId,
//                     revisions: []
//                 };
//             }

//             const existingRevisionIndex = changedData[i].revisions.findIndex(rev => rev.reservation_id === reservationId);

//             if (existingRevisionIndex !== -1) {
//                 changedData[i].revisions[existingRevisionIndex].revision_status = newStatus;
//             } else {
//                 changedData[i].revisions.push({
//                     reservation_id: reservationId,
//                     revision_status: newStatus
//                 });
//             }

//             const updatedReservationList = data.reservation_list.map((reserve, index) => {
//                 if (index === innerIndex) {
//                     return {
//                         ...reserve,
//                         revision_status: newStatus
//                     };
//                 }
//                 return reserve;
//             });

//             return {
//                 ...data,
//                 reservation_list: updatedReservationList
//             };
//         }
//         return data;
//     });

//     setList(updatedReservations);
// }; 
  
  const SingleOptions = [
    { value: "jan", label: "January" },
    { value: "feb", label: "February" },
    { value: "mar", label: "March" },
    { value: "apr", label: "April" },
    { value: "may", label: "May" },
    { value: "jun", label: "June" },
    { value: "jul", label: "July" },
    { value: "aug", label: "August" },
    { value: "sep", label: "September" },
    { value: "oct", label: "October" },
    { value: "nov", label: "November" },
    { value: "dec", label: "December" },
  ];

  const getSearch = async (e) => {
    let isFormValid = true;

    const errors = {};

    if (selectedMonth.length===0) {
      isFormValid = false;
      errors.selectedMonth = "This field is required!";
    }
    if (!selectedYear) {
      isFormValid = false;
      errors.selectedYear = "This field is required!";
    }

    setError(errors);

    if (isFormValid === true) {
      let from;

      // if(tab==="search"){
      //   code=reservationcode
      // }

      // let mail;
      // if(tab==="search"){
      //   mail=email
      // }

      // let customer_name;
      // if(tab==="search"){
      //   customer_name=name
      // }

      const res = await fetch(`${Config.apiurl}revision/list`, {
        method: "POST",
        body: JSON.stringify({
          hotels: id,
          from: datefrom,
          until: dateto,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      if (res.status === 200) {
        const data = await res.json();
        if (data.status_code === "200" || !data.data) {
          setList(data.data);
          setLoader(false);
        } else {
          console.log("error");
        }
      } else if (res.status === 401) {
        handleUnauthorized();
        getSearch();
      } else {
        console.log("error");
      }
    }
  };


  const SaveInterim = async (e) => {
    e.preventDefault();

    let hotelMap = {};

for (let i = 0; i < changedData.length; i++) {
    let hotel_id = changedData[i].hotel_id;
    let reservation_id = changedData[i].reservation_id;
    let revision_status = changedData[i].revision_status;
    let revision_comments;   
    if(changedData[i].revision_status==="no_show"|| changedData[i].revision_status==="cancelled"
       || changedData[i].revision_status==="modified"){
      revision_comments=changedData[i].comments;
    }  
    let revised_amount;
    if(changedData[i].revision_status==="no_show" || changedData[i].revision_status==="cancelled"){
      revised_amount=0;
    }
    else if(changedData[i].revision_status==="modified"){
      revised_amount=changedData[i].revised_amount;
    }

    if (!hotelMap[hotel_id]) {
        hotelMap[hotel_id] = {
            hotel_id: hotel_id,
            revisions: []
        };
    }

    let existingIndex = hotelMap[hotel_id].revisions.findIndex(rev => rev.reservation_id === reservation_id);

    if (existingIndex !== -1) {
        hotelMap[hotel_id].revisions[existingIndex] = {
            reservation_id: reservation_id,
            revision_status: revision_status,
            ...(revised_amount && { revised_amount: revised_amount }),
            ...(revision_comments && { revision_comments: revision_comments })
        };
    } else {
        hotelMap[hotel_id].revisions.push({
            reservation_id: reservation_id,
            revision_status: revision_status,
            ...(revised_amount && { revised_amount: revised_amount }),
            ...(revision_comments && { revision_comments: revision_comments })
        });
    }
}

let hotelArray = Object.values(hotelMap);
      if(changedData.length>0){
      const res = await fetch(
        `${Config.apiurl}revision/bulk-submit`,
        {
          method: "POST",
          body: JSON.stringify({
            hotels: hotelArray,
            ip_address:ip
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      if (res.status === 200) {
        const data = await res.json();
        if (data.status_code !== 200) {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("error");
          setToastlarge(true);
        } else if (data.reauth === true) {
          handleUnauthorized();
          SaveInterim();
        } else {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("success");
          setToastlarge(true);
          getSearch();
          setChangedData([]);
        }
      } else if (res.status === 401) {
        handleUnauthorized();
        SaveInterim();
      } else {
        console.log("error");
      }
    }
    else{
      setToastmodal(true);
      setToastmessage("You have made no changes to save");
      setToasttype("success");
      setToastlarge(true);
    }
  };

  const MonthConclude = async () => {

    let hotelMap = {};

    for (let i = 0; i < list[0]?.reservation_list?.length; i++) {
      let hotel_id = list[0]?.reservation_list[i]?.hotel_id;
      let reservation_id = list[0]?.reservation_list[i]?.reservation_id;
      let revision_status = list[0]?.reservation_list[i]?.revision_status;
      let revision_comments;   
      if(list[0]?.reservation_list[i]?.revision_status==="no_show"|| list[0]?.reservation_list[i]?.revision_status==="cancelled"
         || list[0]?.reservation_list[i]?.revision_status==="modified"){
        revision_comments=list[0]?.reservation_list[i]?.comments;
      }  
      let revised_amount;
      if(list[0]?.reservation_list[i]?.revision_status==="no_show" || list[0]?.reservation_list[i]?.revision_status==="cancelled"){
        revised_amount=0;
      }
      else if(list[0]?.reservation_list[i]?.revision_status==="modified"){
        revised_amount=list[0]?.reservation_list[i]?.revised_amount;
      }
  
      if (!hotelMap[hotel_id]) {
          hotelMap[hotel_id] = {
              hotel_id: hotel_id,
              revisions: []
          };
      }
  
      let existingIndex = hotelMap[hotel_id].revisions.findIndex(rev => rev.reservation_id === reservation_id);
  
      if (existingIndex !== -1) {
          hotelMap[hotel_id].revisions[existingIndex] = {
              reservation_id: reservation_id,
              revision_status: revision_status,
              ...(revised_amount && { revised_amount: revised_amount }),
              ...(revision_comments && { revision_comments: revision_comments })
          };
      } else {
          hotelMap[hotel_id].revisions.push({
              reservation_id: reservation_id,
              revision_status: revision_status,
              ...(revised_amount && { revised_amount: revised_amount }),
              ...(revision_comments && { revision_comments: revision_comments })
          });
      }
  }
  
  let hotelArray = Object.values(hotelMap);

      const res = await fetch(
        `${Config.apiurl}revision/conclude`,
        {
          method: "POST",
          body: JSON.stringify({
            hotels: hotelArray,
            ip_address:ip
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      if (res.status === 200) {
        const data = await res.json();
        if (data.status_code !== 200) {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("error");
          setToastlarge(true);
        } else if (data.reauth === true) {
          handleUnauthorized();
          MonthConclude();
        } else {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("success");
          setToastlarge(true);
          getSearch();
        }
      } else if (res.status === 401) {
        handleUnauthorized();
        MonthConclude();
      } else {
        console.log("error");
      }
  };

  // const MarkCorrect = () => {
  //   const updatedReservations = list.map((data) => {
  //     const updatedReservationList = data.reservation_list.map((reserve) => {
  //       if (reserve.revision_status === "not_revised") {
  //         return {
  //           ...reserve,
  //           revision_status: "correct"
  //         };
  //       }
  //       return reserve;
  //     });
  
  //     return {
  //       ...data,
  //       reservation_list: updatedReservationList
  //     };
  //   });
  
  //   setList(updatedReservations);
  // };
  
  const MarkCorrect = () => {
  
    const today = moment(new Date()).format("YYYY-MM-DD");

    const updatedReservations = list.map((data) => {
      const updatedReservationList = data.reservation_list.map((reserve) => {
        if (reserve.revision_status === "not_revised" && reserve.check_out < today) {
          correctall.push({
            hotel_id: reserve.hotel_id,
            reservation_id: reserve.reservation_id,
            revision_status: "correct"
          });
  
          return {
            ...reserve,
            revision_status: "correct"
          };
        }
        return reserve;
      });
  
      return {
        ...data,
        reservation_list: updatedReservationList
      };
    });
  
    setList(updatedReservations);

    setTimeout(() => {
      MarkCorrectNotRevised();
    }, 0);

  };

  const MarkCorrectNotRevised = async () => {

    let hotelMap = {};

    for (let i = 0; i < correctall?.length; i++) {
  
        let hotel_id =correctall[i].hotel_id;
        let reservation_id = correctall[i].reservation_id;
        let revision_status = correctall[i].revision_status;
  
        if (!hotelMap[hotel_id]) {
            hotelMap[hotel_id] = {
                hotel_id: hotel_id,
                revisions: []
            };
        }
        let existingIndex = hotelMap[hotel_id].revisions.findIndex(rev => rev.reservation_id === reservation_id);
  
        if (existingIndex !== -1) {
            hotelMap[hotel_id].revisions[existingIndex] = {
                reservation_id: reservation_id,
                revision_status: revision_status,
            };
        } else {
            hotelMap[hotel_id].revisions.push({
                reservation_id: reservation_id,
                revision_status: revision_status,
            });
        }
    }
  
  let hotelArray = Object.values(hotelMap);
    if(correctall.length>0){
      const res = await fetch(
        `${Config.apiurl}revision/set-correct`,
        {
          method: "POST",
          body: JSON.stringify({
            hotels: hotelArray,
            ip_address:ip
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      if (res.status === 200) {
        const data = await res.json();
        if (data.status_code !== 200) {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("error");
          setToastlarge(true);
        } else if (data.reauth === true) {
          handleUnauthorized();
          MarkCorrectNotRevised();
        } else {
          setToastmodal(true);
          setToastmessage(data.message);
          setToasttype("success");
          setToastlarge(true);
          getSearch();
        }
      } else if (res.status === 401) {
        handleUnauthorized();
        MarkCorrectNotRevised();
      } else {
        console.log("error");
      }
    } else {
      setToastmodal(true);
      setToastmessage("There are no options for marking as correct");
      setToasttype("success");
      setToastlarge(true);
    }
  };

  function processDBValue(dbValue) {
    if (dbValue === null || dbValue === undefined) {
      return "";
    }
    dbValue = dbValue.toString();
    const numericValue = parseFloat(dbValue.replace(/,/g, ""));
    const result = numericValue !== 0 ? numericValue.toFixed(2) : dbValue;
    return result.endsWith(".00") ? result.slice(0, -3) : result;
  }

  const getMonthIndex = (value) => {
    const months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
    return months.indexOf(value);
  };
  
  useEffect(() => {
    if (selectedMonth && selectedYear) {
      const monthIndex = getMonthIndex(selectedMonth.value);
      const year = parseInt(selectedYear.value, 10);

      const startDate = new Date(year, monthIndex, 1);
      const endDate = new Date(year, monthIndex + 1, 0);

      setDateFrom(moment(startDate).format("DD-MM-YYYY"));
      setDateTo(moment(endDate).format("DD-MM-YYYY"));
    }
  }, [selectedMonth, selectedYear]);


  useEffect(() => {
    setId(hotel_selectid);
  }, [hotel_selectid]);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const yearRange = [];
    for (let i = -3; i <= 6; i++) {
      yearRange.push({ value: currentYear + i, label: (currentYear + i).toString() });
    }
    setYear(yearRange);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
   
        const response = await fetch(
          "https://widgets.thedirectrate.com/api/ips"
        );
     
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
  
        setIp(data.ip);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  
console.log(list);

  return (
    <div className="main-content">
      <Reservationheader />
      <div className="page-content reservation-page-content">
        <div className="">
          <div className="project-wrapper">
            <div className="row">
              <div className="col-xxl-12">
                <div className="card">
                  <div className="reservation-fiter-section">
                    <div className="resevation-fiter-main radio r_code_display">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="input-group">
                            <span
                              className="input-group-text input-joint-color"
                              id="inputGroup-sizing-sm"
                            >
                              Month
                            </span>
                            <Select
                              placeholder="Select..."
                              className="form-control"
                              data-choices
                              name="choices-single-default"
                              id="choices-single-default"
                              value={selectedMonth}
                              onChange={(e)=>setSelectedMonth(e)}
                              options={SingleOptions}
                            />
                          </div>
                          <label style={{ color: "red" }}>
                              {Error.selectedMonth}
                            </label>
                        </div>
                        <div className="col-md-3">
                          <div className="input-group">
                            <span
                              className="input-group-text input-joint-color"
                              id="inputGroup-sizing-sm"
                            >
                              Year
                            </span>
                            {/* <input type="text" className="form-control" 
                            value={selectedYear}
                            onChange={(e)=>setSelectedYear(e.target.value)}
                            /> */}
                             <Select
                              placeholder="Select..."
                              className="form-control"
                              data-choices
                              name="choices-single-default"
                              id="choices-single-default"
                              value={selectedYear}
                              onChange={(e)=>setSelectedYear(e)}
                              options={year}
                            />
                            
                          </div>
                          <label style={{ color: "red" }}>
                              {Error.selectedYear}
                            </label>
                        </div>
                        {/* <div className="col-md-3">
                          <div className="input-group">
                            <span
                              className="input-group-text input-joint-color"
                              id="inputGroup-sizing-sm"
                            >
                              From{" "}
                              <span className="form-validation-required">
                                *
                              </span>
                            </span>
                            <Flatpickr
                              className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                              placeholder="FROM"
                              id="flatpickr-from"
                              options={{
                                mode: "single",
                                dateFormat: "d M, Y",
                              }}
                              value={datefrom}
                              onChange={handleCalendarChangeFrom}
                            />

                            <span
                              className="form_calendar-style fon mdi mdi-calendar-month-outline"
                              onClick={() => handleCalendarIconClick("from")}
                            ></span>
                            <label style={{ color: "red" }}>
                              {Error.datefrom}
                            </label>
                          </div>
                        </div>
                        <div className=" col-md-3">
                          <div className="input-group">
                            <span
                              className="input-group-text input-joint-color"
                              id="inputGroup-sizing-sm"
                            >
                              To
                              <span className="form-validation-required">
                                *{" "}
                              </span>
                            </span>
                            <Flatpickr
                              className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                              placeholder="To"
                              id="flatpickr-until"
                              options={{
                                mode: "single",
                                dateFormat: "d M, Y",
                              }}
                              value={dateto}
                              onChange={handleCalendarChangeTo}
                            />

                            <span
                              className="form_calendar-style fon mdi mdi-calendar-month-outline"
                              onClick={() => handleCalendarIconClick("until")}
                            ></span>
                            <label style={{ color: "red" }}>
                              {Error.dateto}
                            </label>
                          </div>
                        </div> */}
                        <div className="col-md-1 ">
                          <div
                            className="reservation-search-btn-sec"
                            onClick={getSearch}
                          >
                            <Link className="reservation-search-btn">
                              <span className="ri  ri-search-line reser_search_icon"></span>
                              <span className="reserv_search_label">
                                Search
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {list && list[0].basic_data?.length > 0?
              <>
              {list[0]?.monthly_conclude_status===0?
               <div className="col-xxl-12">
              <div className="modify-period-submit">
              <button type="button" className="btn revision_interim_button" onClick={SaveInterim}>
                Save Interim
              </button>
              </div>
              
              <div className="modify-period-submit" style={{paddingRight:"20px"}}>
              <button type="button" className="btn revision_interim_button" onClick={MonthConclude}>
                Save and conclude month
              </button>
              </div>
            </div>
            :
            <div className="col-xxl-12" style={{color:"#ff0000",fontWeight:"500"}}>Already concluded for this month and cannot be modified again!</div>
          //   <div className="col-xxl-12">
          //   <div className="modify-period-submit">
          //   <button type="button" className="btn revision_interim_button_dsable" 
          //   title="Already concluded for this month and cannot be modified" style={{cursor:"not-allowed"}}>
          //     Save Interim
          //   </button>
          //   </div>
            
          //   <div className="modify-period-submit" style={{paddingRight:"20px"}}>
          //   <button type="button" className="btn revision_interim_button_dsable"
          //    title="Already concluded for this month and cannot be modified" style={{cursor:"not-allowed"}}>
          //     Save and conclude month
          //   </button>
          //   </div>
          // </div>
            }
            </>
            :""}
            </div>
            
            {list && list[0].basic_data?.length > 0  ? (
              <>
                {list?.map((data, i) => {
                  return (
                    <>
                      <div className="reservation-main-sec" key={i}>
                        <div className="row">
                          <div className="col-xxl-12">
                            <div className="reservation-filter-results">
                              <table className="table reservation-list-table">
                                <tr>
                                  <th></th>
                                  <th
                                    className="reserv-style-th-new"
                                    title="Reservations"
                                  >
                                    Reservations
                                  </th>
                                  <th
                                    className="reserv-style-th-new"
                                    title="Average Nights per Room"
                                  >
                                    Average Nights per Room
                                  </th>
                                  {/* <th className="reserv-style-th-new"  title="Total Nights"> Total Nights
                                    </th> */}
                                  <th
                                    className="reserv-style-th-new"
                                    title="Total Room Nights"
                                  >
                                    Total Room Nights
                                  </th>
                                  <th
                                    className="reserv-style-th-new"
                                    title="Average Price Without Tax"
                                  >
                                    Average Price
                                  </th>
                                  {/* <th className="reserv-style-th-new"  title="Total Upselling">
                                        Total Upselling 
                                    </th> */}
                                  <th
                                    className="reserv-style-th-new"
                                    title="Production (Without Tax)"
                                  >
                                    Production
                                  </th>
                                  <th
                                    className="reserv-style-th-new"
                                    title="Production with Extras (Without Tax)"
                                  >
                                    Production with Extras
                                  </th>
                                </tr>
                                {data.basic_data.map((basic, ind) => {
                                  return (
                                    <>
                                      <tr>
                                        <td> Initial Reservations</td>
                                        <td>
                                          {
                                            basic.initial_reservations
                                              .total_reservation
                                          }
                                        </td>
                                        <td>
                                          {
                                            basic.initial_reservations
                                              .average_room_nights
                                          }
                                        </td>
                                        <td>
                                          {
                                            basic.initial_reservations
                                              .total_room_nights
                                          }
                                        </td>
                                        <td>
                                          {
                                           processDBValue(basic.initial_reservations.average_price)}{" "}
                                          {basic.currency.code}
                                        </td>
                                        <td>
                                          {processDBValue(basic.initial_reservations.reservation_total_without_extra)}{" "}
                                          {basic.currency.code}
                                        </td>
                                        <td>
                                          {processDBValue(basic.initial_reservations.reservation_total_with_extra)}{" "}
                                          {basic.currency.code}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          {" "}
                                          After cancellations & saved revisions
                                        </td>
                                        <td>
                                          {
                                            basic.save_reservations
                                              .total_reservation
                                          }
                                        </td>
                                        <td>
                                          {
                                            basic.save_reservations
                                              .average_room_nights
                                          }
                                        </td>
                                        <td>
                                          {
                                            basic.save_reservations
                                              .total_room_nights
                                          }
                                        </td>
                                        <td>
                                          {processDBValue(basic.save_reservations.average_price)}{" "}
                                          {basic.currency.code}
                                        </td>
                                        <td>
                                          {processDBValue(basic.save_reservations.reservation_total_without_extra)}{" "}
                                          {basic.currency.code}
                                        </td>
                                        <td>
                                          {processDBValue(basic.save_reservations.reservation_total_with_extra)}{" "}
                                          {basic.currency.code}
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                              </table>
                            </div>
                          </div>
                          {list[0]?.all_correct_status===1?
                          <>
                           {list[0]?.monthly_conclude_status===0?
                          <div className="col-xxl-12 col-sm-12">
                          <div className="modify-period-submit">
                          <button type="button" className="btn revision_correctall_button" onClick={MarkCorrect}>
                          Mark all not revised reservations as correct
                          </button>
                          </div>
                        </div>
                        :""}
                        </>
                        :
                       ""
                        }
                        </div>
                        
                        <div className="row">
                          <div className="col-xxl-12">
                            <div
                              style={{ overflowX:"auto" }}
                            >
                              <table className="table reservation-list-table reservation-main-table-style">
                                <tr>
                                  <th
                                    className="serial-number-new"
                                    title="Code"
                                  >
                                    Code
                                  </th>
                                  <th className="new-head" title="Hotel Name">
                                    Hotel Name
                                  </th>
                                  <th
                                    className="new-head"
                                    title="Reserved Date"
                                  >
                                    Reserved Date
                                  </th>
                                  <th className="new-head" title="Status">
                                    Status
                                  </th>
                                  <th
                                    className="new-head"
                                    title="Promotional/Agency/Company Code"
                                  >
                                    Promotional/Agency..
                                  </th>
                                  <th
                                    className="new-head"
                                    title="Customer Name"
                                  >
                                    Customer Name
                                  </th>
                                  <th className="new-head" title="Check in">
                                    Check in
                                  </th>
                                  <th className="new-head" title="Check out">
                                    Check out
                                  </th>
                                  <th className="new-head" title="Type">
                                    Type
                                  </th>
                                  <th className="new-head" title="No.of Rooms">
                                    No.of Rooms
                                  </th>
                                  <th className="new-head" title="Commission">
                                  Commission
                                  </th>
                                  <th className="new-head" title="Subtotal">
                                    Subtotal
                                  </th>
                                  <th className="new-head" title="Total">
                                    Total
                                  </th>
                                  {/* <th className="new-head" title="Commission">
                                    Commission
                                  </th> */}
                                </tr>
                                {data.reservation_list.map((reserve, index) => {
                                  return (
                                    <>
                                      <tr key={index}>
                                        <td rowSpan="2"
                                          className="serial-number-new-td"
                                          title={reserve.reservation_key}
                                        >
                                          {reserve.reservation_key}
                                        </td>
                                        <td
                                          className="scrollable-cols"
                                          title={reserve.hotel_name}
                                        >
                                          {reserve.hotel_name}
                                        </td>
                                        <td
                                          className="scrollable-cols"
                                          title={reserve.reservation_date}
                                        >
                                          {reserve.reservation_date}
                                        </td>

                                        {reserve.is_revised===true && reserve.is_revision_closed===true?
                                           <td className="tb-head">
                                           <select
                                             className="form-select"
                                             name="condition"
                                             style={{
                                               width: "120px",
                                               marginLeft: "5px",
                                               height: "30px",
                                               padding: "5px",
                                             }}
                                             value={reserve.revision_status}
                                             // onChange={(e) =>handleRevisionStatusChange(i,index,e.target.value)}
                                           >
                                             <option value="not_revised">
                                               Not Revised
                                             </option>
                                             <option value="cancelled">
                                               Cancelled
                                             </option>
                                             <option value="correct">
                                               Correct
                                             </option>
                                             <option value="modified">
                                               Modified
                                             </option>
                                             <option value="no_show">
                                               No Show
                                             </option>
                                             <option value="repeated">
                                               Repeated
                                             </option>
                                           </select>
                                         </td>
                                       
                                        :
                                        <td className="tb-head">
                                        <select
                                          className="form-select"
                                          name="condition"
                                          style={{
                                            width: "120px",
                                            marginLeft: "5px",
                                            height: "30px",
                                            padding: "5px",
                                          }}
                                          value={reserve.revision_status}
                                          onChange={(e) =>handleRevisionStatusChange(i,index,e.target.value)}
                                        >
                                          <option value="not_revised">
                                            Not Revised
                                          </option>
                                          <option value="cancelled">
                                            Cancelled
                                          </option>
                                          <option value="correct">
                                            Correct
                                          </option>
                                          <option value="modified">
                                            Modified
                                          </option>
                                          <option value="no_show">
                                            No Show
                                          </option>
                                          <option value="repeated">
                                            Repeated
                                          </option>
                                        </select>
                                      </td>
                                       }
                                        {/* <td className="scrollable-cols" title='Cancelled from internet'>Cancelled from..</td> */}
                                        <td
                                          className="scrollable-cols"
                                          title={reserve.promocode}
                                        >
                                          {reserve.promocode}
                                        </td>
                                        <td
                                          className="scrollable-cols"
                                          title={`${reserve.customer_first_name} ${reserve.customer_last_name}`}
                                        >
                                          {reserve.customer_first_name}{" "}
                                          {reserve.customer_last_name}
                                        </td>
                                        <td
                                          className="scrollable-cols"
                                          title={reserve.check_in}
                                        >
                                          {reserve.check_in}
                                        </td>
                                        <td
                                          className="scrollable-cols"
                                          title={reserve.check_out}
                                        >
                                          {reserve.check_out}
                                        </td>
                                        <td
                                          className="scrollable-cols"
                                          style={{ width: "150px" }}
                                        >
                                          {reserve?.room_types?.map(
                                            (room, ind) => {
                                              return (
                                                <span
                                                  key={ind}
                                                  style={{
                                                    textTransform: "capitalize",
                                                  }}
                                                  title={room.name}
                                                >
                                                  {room.name}
                                                  {ind <
                                                    reserve?.room_types.length -
                                                      1 && ","}
                                                  <br />
                                                </span>
                                              );
                                            }
                                          )}
                                        </td>
                                        <td
                                          className="scrollable-cols"
                                          title={reserve.no_of_rooms}
                                        >
                                          {reserve.no_of_rooms}
                                        </td>
                                        <td
                                          className="scrollable-cols"
                                          title={reserve.commission_percentage}
                                        >
                                          {reserve.commission_percentage} %
                                        </td>
                                        <td
                                          className="scrollable-cols"
                                          title={processDBValue(reserve.sub_total)}
                                        >
                                          {processDBValue(reserve.sub_total)} {reserve.currency.code}
                                        </td>
                                        <td
                                          className="scrollable-cols"
                                          title={processDBValue(reserve.total)}
                                        >
                                          {processDBValue(reserve.total)} {reserve.currency.code}
                                        </td>
                                      </tr>
                                      {reserve.revision_status==="cancelled" || reserve.revision_status==="modified"
                                         || reserve.revision_status==="no_show" ?
                                      <tr>
                                        <td colspan="11" class="tb-head">
                                          <span style={{fontWeight:"500"}}>Comments:</span>
                                          {reserve.is_revised===true && reserve.is_revision_closed===true?
                                          <>
                                            <input type="text" className="revision_comment_box" 
                                            value={reserve.comments} 
                                            onChange={(e) => handleCommentsChange(i, index,'comments' ,e.target.value)} disabled/>
                                            <span style={{marginLeft:"5%",fontWeight:"500"}}>
                                            Commission : {processDBValue(reserve.commission_amount)} {reserve.currency.code}
                                            </span>
                                            </>
                                           :
                                           <>
                                           <input type="text" className="revision_comment_box" 
                                           value={reserve.comments} 
                                           onChange={(e) => handleCommentsChange(i, index,'comments' ,e.target.value)}/>
                                            <span style={{marginLeft:"5%",fontWeight:"500"}}>
                                            Commission : {processDBValue(reserve.commission_amount)} {reserve.currency.code}
                                            </span>
                                           </>
                                          }
                                        </td>
                                       {reserve.revision_status==="cancelled" || reserve.revision_status==="no_show"?
                                        <td colspan="4" class="tb-head">
                                          {" "}
                                          0 {reserve.currency.code}
                                          {/* {reserve.currency.code} */}
                                        </td>
                                        :
                                        <td colspan="4" class="tb-head">
                                        {" "}
                                        New Total : 
                                        {reserve.is_revised===true && reserve.is_revision_closed===true?
                                        <>
                                        <input type="number" className="revision_new_total"
                                         value={reserve.revised_amount} 
                                         onChange={(e) => handleCommentsChange(i, index,'revised_amount' ,e.target.value)} disabled/> {reserve.currency.code}
                                         </>
                                         :
                                         <>
                                          <input type="number" className="revision_new_total"
                                         value={reserve.revised_amount} 
                                         onChange={(e) => handleCommentsChange(i, index,'revised_amount' ,e.target.value)}/> {reserve.currency.code}
                                         </>
                                        }
                                      </td>
                                       }
                                      </tr>
                                      :
                                      <tr>
                                        <td colspan="14" class="tb-head">
                                        <input type="text" className="revision_comment_box" style={{border:"0px",width:"64%"}} disabled/>
                                          <span style={{fontWeight:"500"}}>
                                          Commission : {processDBValue(reserve.commission_amount)} {reserve.currency.code}
                                          </span>
                                        </td>
                                      </tr>
                                      }
                                    </>
                                  );
                                })}
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </>
            ) : list && list[0].basic_data?.length === 0?(
              <Nodatafound />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {toastmodal === true ? (
          <Toastmodal
            setToast={setToastmodal}
            message={toastmessage}
            type={toasttype}
            large={toastlarge}
          />
        ) : (
          ""
        )}
      <Footer />
    </div>
  );
}

export default Revision;
